import {
    CLIENT_DATA,
    SET_ACCOUNTS_DATA,
    SET_BALANCES_DATA,
    SET_CLIENT_FEE_SETTING,
    CLIENT_FIAT_BALANCE,
    SET_CRYPTO_BALANCES_DATA,
} from '../actions/actionTypes';

const initialState = {
    clientData: {},
    balancesData: [],
    cryptoBalancesData: [],
    savedBeneficiaryAccounts: [],
    fiatBalance: [],
    totalFiatBalance: 0,
    totalCryptoBalance: 0,
};

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_DATA:
            return {
                ...state,
                clientData: action.payload,
            };
        case CLIENT_FIAT_BALANCE:
            return {
                ...state,
                fiatBalance: action.payload,
            };
        default:
            return state;
    }
};

const setBalancesData = (state, action) => {
    switch (action.type) {
        case SET_BALANCES_DATA:
            return {
                ...state,
                balancesData: action.value.clientBalanceList,
                totalFiatBalance: action.value.totalFiatAmount,
            };
        default:
            return state;
    }
};

const setCryptoBalancesData = (state, action) => {
    switch (action.type) {
        case SET_CRYPTO_BALANCES_DATA:
            return {
                ...state,
                cryptoBalancesData: action.value.clientBalanceList.filter(
                    balance =>
                        balance.currencyShortName !== 'TRC20-USDT' &&
                        balance.currencyShortName !== 'ERC20-USDT',
                ),
                totalCryptoBalance: action.value.totalCryptoAmount,
            };
        default:
            return state;
    }
};

function setAccountsData(state, action) {
    return {
        ...state,
        savedBeneficiaryAccounts: action.value,
    };
}

const setClientFeeSetting = (state, action) => {
    switch (action.type) {
        case SET_CLIENT_FEE_SETTING:
            return {
                ...state,
                clientFeeSetting: action.value,
            };
        default:
            return state;
    }
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_DATA:
            return filtersReducer(state, action);
        case SET_BALANCES_DATA:
            return setBalancesData(state, action);
        case SET_ACCOUNTS_DATA:
            return setAccountsData(state, action);
        case SET_CLIENT_FEE_SETTING:
            return setClientFeeSetting(state, action);
        case SET_CRYPTO_BALANCES_DATA:
            return setCryptoBalancesData(state, action);
        default:
            return state;
    }
};

export default accountReducer;
