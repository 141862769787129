import { useState } from 'react';
import { Divider, Pagination, Spin, Modal } from 'antd';
import {
    getNotificationPriorityComponent,
    getNotificationStatusComponent,
} from '../../utils/common';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon, MobileFilter } from '../../utils/Images';
import NotificationsFilterTags from './NotificationsFilterTags';
import NotificationsDetailsList from './NotificationsDetailsList';
import moment from 'moment';

export default function NotificationsMobileList({
    data,
    loading,
    setPage,
    page,
    totalPages,
    setFilterModal,
    clearQueryRender,
    filters,
    setFilters,
    setFiltersForm,
    handleStatusChange,
    activeRecord,
    detailsLoader,
    handleShowAdditionalInfo,
}) {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [moreInformation, setMoreInformation] = useState(false);
    const notificationCount = useSelector(state => state?.notification?.notificationCount);

    const handleExpandRow = id => {
        setExpandedRowKey(expandedRowKey === id ? null : id);
    };

    const handlePagination = page => {
        setPage(page);
    };

    function showMoreInformation(e) {
        e.stopPropagation();
        setMoreInformation(true);
        console.log(expandedRowKey);
    }

    const ExpandedRow = ({ item }) => {
        return (
            <div className="mt-[12px] p-3 bg-[#FCFCFC] border rounded-lg">
                <div className="">
                    {[
                        { label: 'Type', value: item.type },
                        {
                            label: 'Status',
                            value: getNotificationStatusComponent(item?.status),
                        },
                        {
                            label: 'Date',
                            value: moment(item?.timestamp).format('DD.MM.YYYY HH:mm'),
                        },
                        {
                            label: 'Priority',
                            value: getNotificationPriorityComponent(item?.priority),
                        },
                    ].map(({ label, value }) => (
                        <div
                            key={label}
                            className="flex justify-between items-center font-normal text-[13px] pb-3">
                            <p className="text-darkGray">{label}:</p>
                            {value}
                        </div>
                    ))}
                </div>
                <Divider className="mb-3 mt-0" />
                <div className="font-normal text-[13px] pb-3">
                    <p className="text-darkGray mb-3">Description:</p>
                    {item?.message}
                </div>
                <Divider className="mb-3 mt-0" />
                <button
                    onClick={e => {
                        handleShowAdditionalInfo(item);
                        showMoreInformation(e);
                        handleStatusChange(item);
                    }}
                    className="text-[13px] px-[8px] py-[10px] border-[#d1d1d6] border rounded-lg bg-white">
                    More information
                </button>
            </div>
        );
    };

    return (
        <div className="lg:hidden block">
            <Divider className="m-0" />
            <div className="flex items-center justify-between p-[12px] sm:p-5">
                <h2 className="text-base font-semibold flex items-center">
                    Notifications{' '}
                    {notificationCount > 0 && (
                        <span className="notif__count">{notificationCount}</span>
                    )}
                </h2>
                <img
                    src={`${imageBaseUrl}${MobileFilter}`}
                    className="w-4 h-3 cursor-pointer"
                    onClick={() => setFilterModal(true)}
                    alt=""
                />
            </div>

            <Divider className="m-0" />
            <NotificationsFilterTags
                clearQueryRender={clearQueryRender}
                filters={filters}
                setFilters={setFilters}
                setFiltersForm={setFiltersForm}
            />

            {!loading ? (
                <>
                    {data?.length > 0 &&
                        data?.map((item, key) => {
                            const isExpanded = expandedRowKey === item._id;
                            const isNew = item.isNew;
                            return (
                                <div
                                    key={key}
                                    className="notif__row"
                                    onClick={() => handleExpandRow(item._id)}>
                                    <div className="grid grid-cols-2 items-center">
                                        <div className="">
                                            <div className="flex items-center">
                                                {isNew && <div className="notif-new"></div>}
                                                <p className={isNew ? 'font-medium' : ''}>
                                                    {item?.type}
                                                </p>
                                            </div>
                                            <p className="text-[#51525c]">{item.event}</p>
                                        </div>

                                        <div className="justify-end flex gap-4 items-center">
                                            {getNotificationStatusComponent(item?.status)}
                                            <img
                                                className={isExpanded ? 'rotate-180' : ''}
                                                src={`${imageBaseUrl}${ChevronBottomIcon}`}
                                                alt="arrow down"
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                    {isExpanded && <ExpandedRow item={item} />}
                                </div>
                            );
                        })}
                    <Pagination
                        total={totalPages}
                        responsive
                        className="py-3 flex justify-center bg-[#F5F5F5]"
                        current={page}
                        onChange={handlePagination}
                        showSizeChanger={false}
                    />
                </>
            ) : (
                <div className="flex items-center justify-center h-[50vh] ">
                    <Spin />
                </div>
            )}

            <Modal
                className="notif-moreInformation"
                centered
                title="Transaction"
                footer={false}
                onCancel={() => setMoreInformation(false)}
                open={moreInformation}>
                <NotificationsDetailsList item={activeRecord} detailsLoader={detailsLoader} />
            </Modal>
        </div>
    );
}
