import React, { useEffect, useState } from 'react';
import { Divider, Input, Pagination, Space, Spin, Table } from 'antd';
import apiRequest from '../../utils/api';
import moment from 'moment';
import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import useDebounce from '../../utils/useDebounce';
import CustomPagination from '../layout/CustomPagination';

const AuditTrail = ({ tab }) => {
    const email = localStorage.getItem('email');
    const [search, setSearch] = useState('');
    const [data, setData] = useState();

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
    });
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const columns = [
        {
            title: 'Event Type',
            dataIndex: 'eventType',
            key: 'eventType',
        },
        {
            title: 'Event ID',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Account ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'IP Address',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
        },
        {
            title: 'Page',
            dataIndex: 'pageUrl',
            key: 'pageUrl',
        },
        {
            title: 'Created AT',
            dataIndex: 'timestamp',
            key: 'timestamp',
            sorter: true,
            render: row => {
                return <div>{moment(row.timestamp).format('DD/MM/YYYY - HH:mm')}</div>;
            },
        },
    ];

    const getAuditTrails = async (page, eventType) => {
        setLoading(true);
        const body = {
            email,
            page,
            limit: 10,
        };
        if (eventType) {
            body.eventType = eventType;
        }
        const response = await apiRequest('/get-audit-trails', 'POST', body);
        if (response.success) {
            setData(response.data.auditTrails);
            setPagination(prev => ({
                ...prev,
                total: response.data.totalCount,
                current: page,
            }));
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tab === '2') {
            getAuditTrails(1);
        }
    }, [tab]);

    // Helper function to remove specific keys from the object
    const removeFields = record => {
        const fieldsToRemove = [
            'eventType',
            'eventId',
            'userId',
            'ipAddress',
            'pageUrl',
            'timestamp',
        ];
        // Use Object.entries and Object.fromEntries to filter out specific keys
        const filteredRecord = Object.fromEntries(
            Object.entries(record).filter(([key]) => !fieldsToRemove.includes(key)),
        );
        return filteredRecord;
    };

    const searchAuditTrails = useDebounce(value => {
        getAuditTrails(1, value);
    }, 500);

    const handleTableChange = (pagination, filter) => {
        const { current } = pagination;
        setPagination(prevPagination => ({
            ...prevPagination,
            current: current,
        }));

        getAuditTrails(current, search);
    };
    const handlePagination = page => {
        setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
        }));
        getAuditTrails(page, search);
    };

    const ExpandedRow = ({ record }) => {
        const expandedRowFields = [
            { label: 'Event Type', value: record.eventType },
            { label: 'Event ID', value: record?.eventId },
            { label: 'Account ID', value: record.userId },
            { label: 'IP Address', value: record.ipAddress },
            { label: 'Page', value: record.pageUrl },
            {
                label: 'Created',
                value: moment(record.timestamp).format('DD/MM/YYYY - HH:mm'),
            },
        ];
        return (
            <div className="p-3 bg-[#FCFCFC] border rounded-lg">
                {expandedRowFields.map(({ label, value }) => (
                    <p
                        key={label}
                        className="flex justify-between items-center font-normal text-xs text-darkGray pb-3">
                        <span>{label}:</span> {value}
                    </p>
                ))}
                <Divider className="my-4" />
                <div className="flex justify-center">
                    <div className=" py-3 px-auto text-base font-mono w-fit">
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {JSON.stringify(removeFields(record), null, 2)}
                        </pre>
                    </div>
                </div>
            </div>
        );
    };
    const handleExpandRow = key => {
        setExpandedRowKey(expandedRowKey === key ? null : key);
    };
    return (
        <>
            <div className="pt-8 lg:block hidden w-full accounts-page">
                <div className="flex items-center justify-between px-6">
                    <h2 className="page-title">Audit Trail</h2>
                    <Space>
                        <Input
                            value={search}
                            placeholder="Search Event Type"
                            data-e2e="search-bar"
                            suffix={<SearchOutlined />}
                            className="w-[460px] p-2"
                            name="searchText"
                            onChange={e => {
                                setSearch(e.target.value);
                                searchAuditTrails(e.target.value);
                            }}
                            allowClear
                        />
                    </Space>
                </div>
                <Table
                    dataSource={data}
                    loading={loading}
                    className="rounded-b-lg custom-table mt-6 settings-table"
                    columns={columns}
                    rowKey={record => record._id}
                    pagination={{
                        position: ['bottomCenter'],
                        itemRender: CustomPagination,
                        showSizeChanger: false,
                        current: pagination.current,
                        total: pagination.total,
                    }}
                    expandable={{
                        expandedRowRender: record => (
                            <div className="flex justify-center">
                                <div className=" py-3 px-auto text-base font-mono w-fit">
                                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                        {JSON.stringify(removeFields(record), null, 2)}
                                    </pre>
                                </div>
                            </div>
                        ),
                    }}
                    onChange={handleTableChange}
                />
            </div>
            <div className="block lg:hidden">
                {!loading ? (
                    <>
                        <div className="flex justify-between items-center mx-3 gap-2">
                            <h2 className="text-base font-semibold  my-4">Audit Trail</h2>
                            <Input
                                value={search}
                                placeholder="Search Event Type"
                                data-e2e="search-bar"
                                suffix={<SearchOutlined />}
                                className="w-3/4 p-2 h-8"
                                name="searchText"
                                onChange={e => {
                                    setSearch(e.target.value);
                                    searchAuditTrails(e.target.value);
                                }}
                                allowClear
                            />
                        </div>

                        {data?.map((item, key) => {
                            const isExpanded = expandedRowKey === key;
                            return (
                                <div key={key} className="bg-white border-b border-[#E4E4E7]">
                                    <div className="flex justify-between items-center p-3 cursor-pointer">
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <h1 className="text-sm font-medium pb-1 text-[#18181B]">
                                                    {item.eventType || 'N/A'}
                                                </h1>
                                                <p className="font-normal text-xs text-darkGray">
                                                    {item.eventId || 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex gap-4 items-center">
                                            <div className="" onClick={() => handleExpandRow(key)}>
                                                {isExpanded ? (
                                                    <UpOutlined width={10} height={10} />
                                                ) : (
                                                    <DownOutlined width={10} height={10} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {isExpanded && (
                                        <div className="p-3">
                                            <ExpandedRow record={item} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        <Pagination
                            total={pagination.total}
                            responsive
                            className="py-3 flex justify-center"
                            current={pagination.current}
                            onChange={handlePagination}
                        />
                    </>
                ) : (
                    <div className="flex items-center justify-center h-[50vh] ">
                        <Spin />
                    </div>
                )}
            </div>
        </>
    );
};

export default AuditTrail;
