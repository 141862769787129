import React, { useEffect, useState } from 'react';
import { CopyButton, getCurrencySymbol, getExplorerLink } from '../../utils/common';
import moment from 'moment';
import { fiatCurrencies } from '../../utils/constant';
import { useSelector } from 'react-redux';
import apiRequest from '../../utils/api';
import { message } from 'antd';

export const CryptoTransactionInfo = ({ transaction, statusBg, statusText }) => {
    const email = localStorage.getItem('email');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const clientId = transaction.clientId;
    const imgBaseUrl = useSelector(state => state.config.image_base_url);
    const caasTransactionUrl = useSelector(state => state.config.CAAS_ADMIN_UI_TRANSACTION_URL);
    const [cryptoList, setCryptoList] = useState([]);

    const getCryptoCurrency = async () => {
        const requestBody = {
            email,
            paymentProviderId,
            clientId,
        };
        try {
            const response = await apiRequest('/get-crypto-balance', 'POST', requestBody);
            if (response.success) {
                setCryptoList(response?.data?.clientBalanceList);
            } else {
                message.error('Failed to fetch crypto currency icons');
            }
        } catch (error) {
            message.error('Failed to fetch crypto currency icons');
        }
    };

    const renderImageOrAltText = (path, altText) => {
        return path ? (
            <img
                className="mr-[10px] h-[24px] w-[24px]"
                src={`${imgBaseUrl}${path}`}
                alt={altText}
            />
        ) : (
            <span className="mr-[10px] text-sm text-gray-500">{altText}</span>
        );
    };

    useEffect(() => {
        getCryptoCurrency();
    }, []);
    const getFiatCurrency = () => {
        return fiatCurrencies.find(
            currency => currency.symbol === transaction?.transactionDetails[0]?.fiatCurrency,
        );
    };

    const getSlicedAddress = address => {
        return `${address.slice(0, 9)}...${address.slice(-5)}`;
    };

    const getSlicedHash = txHash => {
        return txHash && `${txHash.slice(0, 8)}...`;
    };

    const transactionType = transaction.type;
    const fiatCurrency =
        transactionType === 'Sell' || transactionType === 'Buy' ? getFiatCurrency() : null;
    const cryptoCurrency =
        transactionType === 'Send' ||
        transactionType === 'Buy' ||
        transactionType === 'Receive' ||
        transactionType === 'receive'
            ? cryptoList.find(
                  crypto =>
                      crypto.currencyShortName === transaction?.transactionDetails[0]?.cryptoId ||
                      crypto.currencyShortName === transaction?.transactionDetails[0]?.crypto,
              )
            : null;
    const toCryptoCurrency =
        transactionType === 'Exchange'
            ? cryptoList.find(
                  crypto =>
                      crypto.currencyShortName === transaction?.transactionDetails[0]?.toCryptoId,
              )
            : null;
    const fromCryptoCurrency =
        transactionType === 'Exchange'
            ? cryptoList.find(
                  crypto =>
                      crypto.currencyShortName === transaction?.transactionDetails[0]?.fromCryptoId,
              )
            : null;

    const isBoldLabel = label => {
        return label === 'You Receive' || label === 'You Sent' || label === 'Total';
    };

    const kytStatus =
        !transaction?.transactionDetails[0]?.kytStatus ||
        transaction?.transactionDetails[0]?.kytStatus === 'failed' ? (
            <p className="text-red-800 bg-[#FEF2F2] rounded-full py-1 px-5 text-sm font-medium">
                Failed
            </p>
        ) : (
            <p className="text-green-800 bg-[#ECFDF3] rounded-full py-1 px-5 text-sm font-medium">
                Passed
            </p>
        );

    const pendingStatus = transaction?.transactionDetails[0]?.pendingStatus ? (
        <p className="text-green-800 bg-[#ECFDF3] rounded-full py-1 px-5 text-sm font-medium">
            {transaction?.transactionDetails[0]?.status}
        </p>
    ) : (
        '-'
    );

    const symbol =
        transactionType === 'Exchange'
            ? toCryptoCurrency?.currencyShortName
            : cryptoCurrency?.currencyShortName;

    const getValueField = (label, item) => {
        switch (label) {
            // case 'KYT Status':
            //     return kytStatus;
            case 'Receive Wallet':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(fiatCurrency?.icon, 'Fiat Icon')}
                        {fiatCurrency?.name} ({fiatCurrency?.symbol})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Sell Amount':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`${getCurrencySymbol(transaction?.transactionDetails[0]?.cryptoId)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Receive Amount':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`${getCurrencySymbol(fiatCurrency?.symbol)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Sell Exchange Rate':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`1 ${fiatCurrency?.symbol} = ${Number(item.value).toFixed(6)} ${transaction?.transactionDetails[0]?.cryptoId}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Transaction Status':
                return <p className={statusBg}>{statusText}</p>;
            case 'Pending Status':
                return pendingStatus;
            case 'Recipient Wallet':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(cryptoCurrency?.currencyIcon, 'Crypto Icon')}
                        {cryptoCurrency?.currencyFullName} ({cryptoCurrency?.currencyShortName})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'From Currency':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(fromCryptoCurrency?.currencyIcon, 'From Crypto Icon')}
                        {fromCryptoCurrency?.currencyFullName} (
                        {fromCryptoCurrency?.currencyShortName})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'To Currency':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(toCryptoCurrency?.currencyIcon, 'To Crypto Icon')}
                        {toCryptoCurrency?.currencyFullName} ({toCryptoCurrency?.currencyShortName})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'From Amount':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`${getCurrencySymbol(fromCryptoCurrency?.currencyShortName)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'To Amount':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`${getCurrencySymbol(toCryptoCurrency?.currencyShortName)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Exchange Rate':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`1 ${fromCryptoCurrency?.currencyShortName} = ${Number(item.value).toFixed(6)} ${toCryptoCurrency?.currencyShortName}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'You Receive':
                return (
                    <p className="text-base font-semibold text-darkGray">
                        {`${getCurrencySymbol(symbol)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'You Sent':
                return (
                    <p className="text-base font-semibold text-darkGray">
                        {`${getCurrencySymbol(cryptoCurrency?.currencyShortName)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Total':
                return (
                    <p className="text-base font-semibold text-darkGray">
                        {`${getCurrencySymbol(fiatCurrency?.symbol)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Crypto Bought':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(cryptoCurrency?.currencyIcon, 'Crypto Bought Icon')}
                        {cryptoCurrency?.currencyFullName} ({cryptoCurrency?.currencyShortName})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Pay Wallet':
                return (
                    <p className="text-sm font-normal text-darkGray flex justify-center items-center">
                        {renderImageOrAltText(fiatCurrency?.icon, 'Pay Wallet Icon')}
                        {fiatCurrency?.name} ({fiatCurrency?.symbol})
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Buy Exchange Rate':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`1 ${cryptoCurrency?.currencyShortName} = ${Number(item.value).toFixed(6)} ${fiatCurrency?.symbol}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'Fee Amount':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {`${getCurrencySymbol(fiatCurrency?.symbol)} ${item.value}`}
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'CAAS Transaction ID':
                return (
                    <p
                        className={`text-sm font-normal ${item.value ? 'text-[#1570EF]' : 'text-darkGray'}`}>
                        <a href={`${caasTransactionUrl}/${item?.value}`} target="_blank">
                            {item.value ? getSlicedHash(item.value) : '-'}
                        </a>
                        <CopyButton msg={item.value} />
                    </p>
                );
            case 'From Wallet Address':
                return (
                    <div className="flex items-center justify-center">
                        {item.value !== '-' ? (
                            <a
                                href={getExplorerLink(
                                    item.blockchain,
                                    item.network,
                                    item.value,
                                    false,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm font-normal text-[#1570EF]">
                                {getSlicedAddress(item.value)}
                            </a>
                        ) : (
                            <p className="text-sm font-normal text-darkGray">-</p>
                        )}
                        <CopyButton msg={item.value} />
                    </div>
                );
            case 'Receiving Wallet Address':
                return (
                    <div className="flex items-center justify-center">
                        {item.value !== '-' ? (
                            <a
                                href={getExplorerLink(
                                    item.blockchain,
                                    item.network,
                                    item.value,
                                    false,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm font-normal text-[#1570EF]">
                                {getSlicedAddress(item.value)}
                            </a>
                        ) : (
                            <p className="text-sm font-normal text-darkGray">-</p>
                        )}
                        <CopyButton msg={item.value} />
                    </div>
                );
            case 'Mempool TX Hash':
                return (
                    <div className="flex items-center justify-center">
                        {item.value !== '-' ? (
                            <a
                                href={getExplorerLink(
                                    item.blockchain,
                                    item.network,
                                    item.value,
                                    true,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm font-normal text-[#1570EF]">
                                {getSlicedHash(item.value)}
                            </a>
                        ) : (
                            <p className="text-sm font-normal text-darkGray">-</p>
                        )}
                        <CopyButton msg={item.value} />
                    </div>
                );
            case 'Confirmation TX Hash':
                return (
                    <div className="flex items-center justify-center">
                        {item.value !== '-' ? (
                            <a
                                href={getExplorerLink(
                                    item.blockchain,
                                    item.network,
                                    item.value,
                                    true,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm font-normal text-[#1570EF]">
                                {getSlicedHash(item.value)}
                            </a>
                        ) : (
                            <p className="text-sm font-normal text-darkGray">-</p>
                        )}
                        <CopyButton msg={item.value} />
                    </div>
                );
            case 'Confirmation TX Timestamp':
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {item.value || '-'}
                        <CopyButton msg={item.value} />
                    </p>
                );
            default:
                return (
                    <p className="text-sm font-normal text-darkGray">
                        {item.value || '-'}
                        <CopyButton msg={item.value} />
                    </p>
                );
        }
    };

    const getTransactionDetails = transaction => {
        const transactionDetails = [
            {
                label: 'Transaction Initiated',
                value: moment(transaction.createdDate).format('DD.MM.YY HH:mm') || '-',
            },
            {
                label: 'Last Updated',
                value: moment(transaction.status.updated).format('DD.MM.YY HH:mm') || '-',
            },
            {
                label: 'Transaction Status',
                value: transaction.status || '-',
            },
            { label: 'Transaction ID', value: transaction?.transactionId || '-' },
            {
                label: 'CAAS Transaction ID',
                value:
                    transaction?.caasData?.caasTransactionId ||
                    transaction?.transactionDetails[0]?.caasTransactionId ||
                    transaction?.caasTransactionId ||
                    null,
            },
            { label: 'Blockchain', value: transaction?.transactionDetails[0]?.blockchain || '-' },
            {
                label: 'Network',
                value:
                    transaction?.caasData?.network ||
                    transaction?.transactionDetails[0]?.network ||
                    '-',
            },
            { label: 'Trade ID', value: transaction?.caasData?.tradeDetails?.tradeOrderId || '-' },
            {
                label: 'Crypto ID',
                value:
                    transaction?.transactionDetails[0]?.cryptoId ||
                    transaction?.transactionDetails[0]?.crypto ||
                    '-',
            },
            // { label: 'KYT Status', value: '' || '-' },
        ];
        const sellTransactionDetails = [
            {
                label: 'Sell Amount',
                value: transaction?.transactionDetails[0]?.cryptoAmount || '-',
            },
            {
                label: 'Receive Wallet',
                value: transaction?.transactionDetails[0]?.fiatCurrency || '-',
            },
            {
                label: 'Receive Amount',
                value: transaction?.transactionDetails[0]?.fiatAmount || '-',
            },
            {
                label: 'Sell Exchange Rate',
                value: transaction?.transactionDetails[0]?.exchangeRate || '-',
            },
            {
                label: 'Fee %',
                value:
                    `${transaction?.transactionDetails[0]?.fee ? transaction?.transactionDetails[0]?.fee : '0'}%` ||
                    '-',
            },
            { label: 'Fee Amount', value: transaction?.transactionFee || '-' },
            {
                label: `Total`,
                value: transaction?.transactionDetails[0]?.totalAmount || '-',
            },
        ];
        const exchangeTransactionDetails = [
            {
                label: 'From Currency',
                value: transaction?.transactionDetails[0]?.fromCryptoId || '-',
            },
            {
                label: 'From Amount',
                value: transaction?.transactionDetails[0]?.fromCryptoAmount || '-',
            },
            {
                label: 'To Currency',
                value: transaction?.transactionDetails[0]?.toCryptoId || '-',
            },
            {
                label: 'To Amount',
                value: transaction?.transactionDetails[0]?.toCryptoAmount || '0',
            },
            {
                label: 'Exchange Rate',
                value: transaction?.transactionDetails[0]?.exchangeRate || '-',
            },
            {
                label: 'Fee %',
                value:
                    `${transaction?.transactionDetails[0]?.fee ? transaction?.transactionDetails[0]?.fee : '0'}%` ||
                    '-',
            },
            {
                label: `You Receive`,
                value: transaction?.transactionDetails[0]?.toCryptoAmount || '-',
            },
        ];
        const receiveAndSendTransactionCommonDetails = [
            {
                label: 'Recipient Wallet',
                value: transaction?.caasData?.cryptoAmount || transaction?.caasData?.amount || '-',
            },
            {
                label: 'From Wallet Address',
                value:
                    transaction?.caasData?.fromAddress ||
                    transaction?.caasData?.fromWalletAddress ||
                    transaction?.transactionDetails[0]?.fromAddress ||
                    '-',
                blockchain: transaction?.transactionDetails[0]?.blockchain || '',
                network:
                    transaction?.caasData?.network ||
                    transaction?.transactionDetails[0]?.network ||
                    'testnet',
            },
            {
                label: 'Receiving Wallet Address',
                value:
                    transaction?.caasData?.toAddress ||
                    transaction?.caasData?.toWalletAddress ||
                    transaction?.transactionDetails[0]?.toAddress ||
                    '-',
                blockchain: transaction?.transactionDetails[0]?.blockchain || '',
                network:
                    transaction?.caasData?.network ||
                    transaction?.transactionDetails[0]?.network ||
                    'testnet',
            },
            {
                label: 'Pending Status',
                value: transaction?.caasData?.status || '-',
            },
            {
                label: 'Confirmation TX Hash',
                value:
                    transaction?.caasData?.confirmedTxHash ||
                    transaction?.transactionDetails[0]?.confirmedTxHash ||
                    '-',
                blockchain: transaction?.transactionDetails[0]?.blockchain || '',
                network:
                    transaction?.caasData?.network ||
                    transaction?.transactionDetails[0]?.network ||
                    'testnet',
            },
            {
                label: 'Confirmation TX Timestamp',
                value: transaction?.caasData?.confirmedTxTimestamp
                    ? moment(
                          transaction?.caasData?.confirmedTxTimestamp,
                          'YYYY:MM:DDTHH:mm:ssZ',
                      ).format('DD.MM.YY HH:mm')
                    : '-',
            },
            {
                label: 'Confirmations',
                value: transaction?.caasData?.confirmations || '-',
            },
            // {
            //     label: 'Amount',
            //     value: transaction?.transactionDetails[0]?.cryptoAmount || '-',
            // },
            { label: 'Fee Amount', value: transaction?.transactionFee || '-' },
        ];

        const receiveTransactionDetails = [
            ...receiveAndSendTransactionCommonDetails,
            {
                label: 'You Receive',
                value:
                    transaction?.transactionDetails[0]?.totalAmount ||
                    transaction?.transactionDetails[0]?.cryptoAmount ||
                    '-',
            },
        ];

        const sendTransactionDetails = [
            ...receiveAndSendTransactionCommonDetails,
            {
                label: 'You Sent',
                value: transaction?.transactionDetails[0]?.cryptoAmount || '-',
            },
        ];

        const buyTransactionDetails = [
            {
                label: 'Crypto Bought',
                value: transaction?.transactionDetails[0]?.cryptoId || '-',
            },
            {
                label: 'Crypto Amount',
                value: transaction?.transactionDetails[0]?.cryptoAmount || '-',
            },
            {
                label: 'Pay Wallet',
                value: transaction?.transactionDetails[0]?.fiatCurrency || '-',
            },
            {
                label: 'Fiat Amount',
                value: transaction?.transactionDetails[0]?.fiatAmount || '-',
            },
            {
                label: 'Buy Exchange Rate',
                value: transaction?.transactionDetails[0]?.exchangeRate || '-',
            },
            {
                label: 'Fee %',
                value:
                    `${transaction?.transactionDetails[0]?.fee ? transaction?.transactionDetails[0]?.fee : '0'}%` ||
                    '-',
            },
            { label: 'Fee Amount', value: transaction?.transactionFee || '-' },
            {
                label: 'Total',
                value: transaction?.transactionDetails[0]?.totalAmount || '-',
            },
        ];

        switch (transactionType) {
            case 'Sell':
                return [...transactionDetails, ...sellTransactionDetails];
            case 'Send':
                return [...transactionDetails, ...sendTransactionDetails];
            case 'Exchange':
                return [...transactionDetails, ...exchangeTransactionDetails];
            case 'Buy':
                return [...transactionDetails, ...buyTransactionDetails];
            case 'Receive':
                return [...transactionDetails, ...receiveTransactionDetails];
            case 'receive':
                return [...transactionDetails, ...receiveTransactionDetails];
            default:
                return [];
        }
    };

    return (
        <div className="pb-0 border rounded-lg border-[#E4E4E7] mt-5 bg-[#fcfcfc]">
            {getTransactionDetails(transaction).map((item, index) => (
                <div
                    className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3"
                    key={index}>
                    <p
                        className={`${isBoldLabel(item.label) ? 'text-base font-semibold' : 'text-sm font-normal'} text-darkGray max-md:w-[152px]`}>
                        {item.label}:
                    </p>
                    {getValueField(item.label, item)}
                </div>
            ))}
        </div>
    );
};
