import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form } from 'antd';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import moment from 'moment';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';
import Loading from '../common/Loading';
import { NotesTabDrawer, NotesTabForm, NotesTabList } from '../accountDetailsTabs/AddNotesTab';

const NotesTab = ({ transaction, onUpdate }) => {
    const [editNotes, setEditNotes] = useState(false);
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [deleteNotesModal, setDeleteNotesModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const { id } = useParams();
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const [form] = Form.useForm();

    const handleClose = () => {
        setOpenNotesModal(false);
        setSelectedData({});
    };

    const setOpenAddNotesModal = () => {
        form.resetFields();
        setEditNotes(false);
        setOpenNotesModal(true);
    };

    const handleEdit = note => {
        form.setFieldsValue({
            note: note.data,
        });
        setSelectedData(note);
        setOpenNotesModal(true);
        setEditNotes(true);
        setDetailsModal(false);
    };

    const handleDeleteNotesData = userData => {
        setDeleteNotesModal(true);
        setSelectedData(userData);
    };

    const handleNotesDrawer = userData => {
        setSelectedData(userData);
        setDetailsModal(true);
    };

    const handleAddNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                transactionId: transaction?.transactionId,
                data: formValues.note,
                createdBy: paymentProvider,
            };

            const response = await apiRequest('/add-transaction-note', 'POST', body);

            if (response.success) {
                onUpdate();
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to add note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                transactionId: transaction?.transactionId,
                data: formValues.note,
                createdBy: email,
                noteId: selectedData?._id,
            };

            const response = await apiRequest('/update-transaction-note', 'POST', body);

            if (response.success) {
                onUpdate();
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to edit note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteNotes = async noteId => {
        try {
            setLoading(true);
            const body = {
                email: email,
                paymentProvider: paymentProvider,
                transactionId: transaction?.transactionId,
                noteId: noteId,
            };

            const response = await apiRequest('/delete-transaction-note', 'DELETE', body);

            if (response.success) {
                onUpdate();
            } else {
                message.error(response.error);
            }

            setDetailsModal(false);
            setDeleteNotesModal(false);
        } catch (error) {
            console.error('Failed to delete note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-[#ffffff] lg:p-0 p-[12px]">
            <div className="lg:p-0 lg:mt-0 bg-white">
                <div className="pb-2 pt-4 lg:pt-8 lg:px-0 flex items-center justify-between">
                    <h1 className="text-[#18181B] font-inter text-lg font-semibold leading-[28px]">
                        Notes
                    </h1>
                    <div className="font-medium text-sm">
                        <ThemeButton
                            type="primary"
                            style="lg:inline-flex hidden"
                            icon={<PlusOutlined />}
                            dataE2e="add-notes"
                            text="Add Notes"
                            action={setOpenAddNotesModal}
                        />
                        <ThemeButton
                            style="lg:hidden"
                            type="primary"
                            icon={<PlusOutlined />}
                            dataE2e="add-new"
                            text="Add new"
                            action={setOpenAddNotesModal}
                        />
                    </div>
                </div>
            </div>

            <NotesTabForm
                form={form}
                open={openNotesModal}
                loading={loading}
                editNotes={editNotes}
                handleClose={handleClose}
                handleEditNotes={handleEditNotes}
                handleAddNotes={handleAddNotes}
            />

            <Divider className="hidden lg:block my-2 " />

            {loading ? (
                <Loading />
            ) : (
                transaction?.notes?.map(userData => {
                    const timestamp = userData.createdAt || userData.updatedAt;
                    const formattedDate = moment(timestamp).format('YYYY-MM-DD');
                    const formattedTime = moment(timestamp).format('hh:mm:ss');
                    return (
                        <NotesTabList
                            key={userData?._id}
                            userData={userData}
                            formattedDate={formattedDate}
                            formattedTime={formattedTime}
                            handleEdit={handleEdit}
                            handleDeleteNotesData={handleDeleteNotesData}
                            handleNotesDrawer={handleNotesDrawer}
                        />
                    );
                })
            )}

            <NotesTabDrawer
                open={detailsModal}
                onClose={() => setDetailsModal(false)}
                selectedData={selectedData}
                handleEdit={handleEdit}
                handleDeleteNotesData={handleDeleteNotesData}
            />

            <DeleteModal
                open={deleteNotesModal}
                title={'Delete note'}
                content={'Are you sure you want to delete the note?'}
                toDeleteName={selectedData.data}
                handleClose={() => setDeleteNotesModal(false)}
                handleDelete={() => handleDeleteNotes(selectedData._id)}
            />
        </div>
    );
};

export default NotesTab;
