import { Modal } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const CryptoPendingModal = props => {
    const { open, onCancel, handleConfirm, error } = props;

    return (
        <Modal
            width={422}
            title={null}
            open={open}
            closable={false}
            centered
            onCancel={onCancel}
            footer={
                <>
                    <hr className="mt-6" />
                    <div className="flex justify-center mt-6 mb-2">
                        <button
                            className={`rounded-full px-20 h-[46px] text-[#0f0f0f] bg-gray-300 hover:bg-gray-500 disabled:opacity-50`}
                            onClick={() => {
                                handleConfirm();
                            }}>
                            Track transaction
                        </button>
                    </div>
                </>
            }>
            <div className="relative my-6 flex justify-center items-center">
                <ClockCircleOutlined className="text-[45px]" />
            </div>
            <div className="">
                <p className="text-xl font-bold text-center">Pending...</p>
                <p className="text-base text-center mt-5 text-[#3F3F46]">{error}</p>
            </div>
        </Modal>
    );
};

export default CryptoPendingModal;
