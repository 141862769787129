import React, { useEffect, useState } from 'react';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { Divider, Drawer, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../utils/Images';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import moment from 'moment';
import CommonModal from '../modals/CommonModal';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';
import Loading from '../common/Loading';

const { TextArea } = Input;

export const NotesTabForm = ({
    form,
    open,
    loading,
    editNotes,
    handleClose,
    handleEditNotes,
    handleAddNotes,
}) => {
    return (
        <CommonModal
            title={
                <span className="text-[#18181B] font-inter text-2xl font-semibold leading-[32px]">
                    {editNotes ? 'Edit Note' : 'Add Note'}
                </span>
            }
            loading={loading}
            onFinish={editNotes ? handleEditNotes : handleAddNotes}
            form={form}
            open={open}
            handleClose={handleClose}
            className={'common-mobile-view noteModalTabview'}
            footerText={editNotes ? 'Save' : 'Apply'}>
            <Form
                layout="vertical"
                className="mt-10 modal-form !md:h-[458px] "
                form={form}
                colon={false}
                requiredMark={false}>
                <div className="!md:h-[458px] ">
                    <Form.Item
                        name="note"
                        label={
                            <span className="self-stretch text-[#26272B] font-inter text-base font-normal leading-6 ">
                                Note:
                            </span>
                        }
                        rules={[{ required: true, message: 'Please enter your note.' }]}
                        normalize={value => value}>
                        <TextArea
                            rows={6}
                            placeholder="Add your note..."
                            className=" resize-none  !h-[200px] text-base rounded-[8px] p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[12px] px-[16px]"></TextArea>
                    </Form.Item>
                </div>
            </Form>
        </CommonModal>
    );
};

export const NotesTabList = ({
    userData,
    formattedDate,
    formattedTime,
    handleEdit,
    handleDeleteNotesData,
    handleNotesDrawer,
}) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    return (
        <div key={userData._id}>
            <div className="note-item grid grid-cols-6 justify-between items-center mt-4 rounded-[18px] ">
                <div className="col-span-5 flex flex-col flex-grow">
                    <pre className="text-[#18181B] overflow-hidden text-ellipsis font-inter text-sm font-normal">
                        {userData.data}
                    </pre>
                    <p
                        className=" text-[#70707B]
                    overflow-hidden text-ellipsis font-inter text-[13px] italic font-normal leading-[20px]">
                        {`Added by ${userData.createdBy?.firstName} ${userData.createdBy?.lastName} - ${formattedDate}    ${formattedTime}`}
                    </p>
                </div>

                <div className="flex justify-end gap-2">
                    <div className="hidden  md:block">
                        <button
                            className="border-0 rounded-full bg-[rgb(255,255,255)] p-[10px]"
                            onClick={() => handleEdit(userData)}>
                            <img src={`${imageBaseUrl}${Edit}`} alt="edit" />
                        </button>

                        <button
                            className="border-0 rounded-full bg-[#ffffff] p-[10px]"
                            onClick={() => handleDeleteNotesData(userData)}>
                            <img
                                src={`${imageBaseUrl}${Delete}`}
                                alt="Delete"
                                width={20}
                                height={20}
                            />
                        </button>
                    </div>

                    <div className="flex lg:hidden md:hidden ">
                        <MoreOutlined
                            className="!text-lg inline "
                            onClick={() => handleNotesDrawer(userData)}
                        />
                    </div>
                </div>
            </div>
            <Divider className="my-2 " />
        </div>
    );
};

export const NotesTabDrawer = ({
    open,
    onClose,
    selectedData,
    handleEdit,
    handleDeleteNotesData,
}) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const timestamp = selectedData?.createdAt || selectedData?.updatedAt;
    const formattedDate = moment(timestamp).format('YYYY-MM-DD');
    const formattedTime = moment(timestamp).format('hh:mm:ss');

    return (
        <Drawer
            title={false}
            placement="bottom"
            closable={false}
            onClose={onClose}
            height="auto"
            open={open}
            key="bottom"
            className="w-[375px] lg:hidden rounded-xl border-t border-gray-100 bg-white ">
            <div className="">
                <div className="flex justify-between items-center mb-9">
                    <h1 className="text-[#18181B] font-inter text-lg font-semibold leading-[28px] break-words overflow-wrap break-word whitespace-normal max-w-full">
                        {selectedData.data}
                    </h1>
                    <p className="text-lg font-semibold cursor-pointer" onClick={onClose}>
                        X
                    </p>
                </div>
                <div className="">
                    <p
                        className="text-[#51525C] font-inter text-base italic font-normal leading-6
                ">
                        {`Added by ${selectedData?.createdBy?.firstName} ${selectedData?.createdBy?.lastName} - ${formattedDate}    ${formattedTime}`}
                    </p>
                </div>

                <div className="mt-9 flex justify-between">
                    <div className="flex flex-col items-center gap-1.5 w-[166.5px] h-[68px]">
                        <button
                            className="border-0 rounded-full bg-[#F4F4F5] p-[10px]"
                            onClick={() => handleEdit(selectedData)}>
                            <img src={`${imageBaseUrl}${Edit}`} alt="edit" width={24} height={24} />
                        </button>
                        <p className="text-[#18181B] text-center font-inter text-xs font-normal leading-[18px] mt-2">
                            Edit
                        </p>
                    </div>

                    <div className="flex flex-col items-center gap-1.5 w-[166.5px] h-[68px]">
                        <button
                            className="border-0 rounded-full bg-[#ffffff] p-[10px]"
                            onClick={() => handleDeleteNotesData(selectedData)}>
                            <img
                                src={`${imageBaseUrl}${Delete}`}
                                alt="Delete"
                                width={24}
                                height={24}
                                className="svg-red"
                            />
                        </button>
                        <p className="text-[#18181B] text-center font-inter text-xs font-normal leading-[18px] mt-2">
                            Remove
                        </p>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

const AddNotesTab = () => {
    const [editNotes, setEditNotes] = useState(false);
    const [openNotesModal, setOpenNotesModal] = useState(false);
    const [deleteNotesModal, setDeleteNotesModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detailsNoteData, setDetailsNoteData] = useState([]);
    const [selectedData, setSelectedData] = useState({});

    const { id } = useParams();
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const [form] = Form.useForm();

    useEffect(() => {
        getNotes();
    }, [id]);

    const getNotes = async () => {
        try {
            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
            };

            const response = await apiRequest('/get-notes', 'POST', body);

            if (response.success) {
                setDetailsNoteData(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch notes:', error.response?.data || error.message);
        }
    };

    const handleClose = () => {
        setOpenNotesModal(false);
        setSelectedData({});
    };

    const setOpenAddNotesModal = () => {
        form.resetFields();
        setEditNotes(false);
        setOpenNotesModal(true);
    };

    const handleEdit = note => {
        form.setFieldsValue({
            note: note.data,
        });
        setSelectedData(note);
        setOpenNotesModal(true);
        setEditNotes(true);
        setDetailsModal(false);
    };

    const handleDeleteNotesData = userData => {
        setDeleteNotesModal(true);
        setSelectedData(userData);
    };

    const handleNotesDrawer = userData => {
        setSelectedData(userData);
        setDetailsModal(true);
    };

    const handleAddNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                data: formValues.note,
                createdBy: paymentProvider,
            };

            const response = await apiRequest('/add-note', 'POST', body);

            if (response.success) {
                getNotes();
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to add note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                data: formValues.note,
                createdBy: paymentProvider,
                _id: selectedData._id,
            };

            const response = await apiRequest('/update-note', 'POST', body);

            if (response.success) {
                getNotes();
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to edit note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteNotes = async noteId => {
        try {
            setLoading(true);
            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                _id: noteId,
            };

            const response = await apiRequest('/delete-note', 'DELETE', body);

            if (response.success) {
                getNotes();
            } else {
                message.error(response.error);
            }

            setDetailsModal(false);
            setDeleteNotesModal(false);
        } catch (error) {
            console.error('Failed to delete note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-[#ffffff] lg:p-0 p-[12px]">
            <div className="lg:p-0 lg:mt-0 bg-white">
                <div className="py-6 pb-2 pt-4 lg:pt-8 lg:px-0 flex items-center justify-between">
                    <h1 className="text-[#18181B] font-inter text-lg font-semibold leading-[28px]">
                        Notes
                    </h1>
                    <div className="font-medium text-sm">
                        <ThemeButton
                            type="primary"
                            style="lg:inline-flex hidden"
                            icon={<PlusOutlined />}
                            dataE2e="add-notes"
                            text="Add Notes"
                            action={setOpenAddNotesModal}
                        />
                        <ThemeButton
                            style="lg:hidden"
                            type="primary"
                            icon={<PlusOutlined />}
                            dataE2e="add-new"
                            text="Add new"
                            action={setOpenAddNotesModal}
                        />
                    </div>
                </div>
            </div>

            <NotesTabForm
                form={form}
                open={openNotesModal}
                loading={loading}
                editNotes={editNotes}
                handleClose={handleClose}
                handleEditNotes={handleEditNotes}
                handleAddNotes={handleAddNotes}
            />

            <Divider className="hidden lg:block my-2 " />

            {loading ? (
                <Loading />
            ) : (
                detailsNoteData?.map(userData => {
                    const timestamp = userData.createdAt || userData.updatedAt;
                    const formattedDate = moment(timestamp).format('YYYY-MM-DD');
                    const formattedTime = moment(timestamp).format('hh:mm:ss');
                    return (
                        <NotesTabList
                            key={userData?._id}
                            userData={userData}
                            formattedDate={formattedDate}
                            formattedTime={formattedTime}
                            handleEdit={handleEdit}
                            handleDeleteNotesData={handleDeleteNotesData}
                            handleNotesDrawer={handleNotesDrawer}
                        />
                    );
                })
            )}

            <NotesTabDrawer
                open={detailsModal}
                onClose={() => setDetailsModal(false)}
                selectedData={selectedData}
                handleEdit={handleEdit}
                handleDeleteNotesData={handleDeleteNotesData}
            />

            <DeleteModal
                open={deleteNotesModal}
                title={'Delete note'}
                content={'Are you sure you want to delete the note?'}
                toDeleteName={selectedData.data}
                handleClose={() => setDeleteNotesModal(false)}
                handleDelete={() => handleDeleteNotes(selectedData._id)}
            />
        </div>
    );
};

export default AddNotesTab;
