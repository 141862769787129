import React, { useState, useRef } from 'react';
import { cryptoCurrencyIcon, getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { useSelector } from 'react-redux';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';

export const CryptoSelectModal = ({ options, value, onChange, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const cryptoBalancesData = useSelector(state => state?.account?.cryptoBalancesData);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const selectedOption = options.find(option => option.currencyShortName === value);

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const allowedFiatCurrencies = ['USD', 'EUR', 'GBP'];
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const filteredOptions = options.filter(option => {
        const isFiat = option.currencyType === 'fiat';
        const matchesSearch = option.currencyShortName.toLowerCase().includes(lowerCaseSearchTerm);

        return isFiat
            ? allowedFiatCurrencies.includes(option.currencyShortName) && matchesSearch
            : matchesSearch;
    });

    return (
        <div className="" ref={dropdownRef} data-e2e="dropp-down">
            <div
                className={`bg-input rounded-r-3xl p-[14.5px] cursor-pointer  ${className}`}
                onClick={toggleDropdown}>
                {value ? (
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <img
                                src={`${imageBaseUrl}${selectedOption?.currencyIcon}`}
                                alt={value}
                                className="w-6 h-6"
                            />
                            {value}
                        </div>
                        {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    </div>
                ) : (
                    'Select an option'
                )}
            </div>
            {isOpen && (
                <div className="absolute p-[10px] right-8 !z-50 w-[300px] bg-white border border-gray-300 rounded-md shadow-lg mt-2 max-h-60 overflow-auto hide-scrollbar">
                    <div className="relative">
                        <input
                            type="text"
                            className="w-full p-2 pl-10 rounded-lg border border-[#D1D1D6]"
                            placeholder={`${cryptoCurrencyIcon[value] ? 'Search for crypto...' : 'Search for currency...'}`}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            onClick={e => e.stopPropagation()}
                        />
                        <SearchOutlined
                            className="absolute top-1/2 right-1 transform -translate-y-1/2 text-gray-400"
                            style={{ pointerEvents: 'none' }}
                        />
                    </div>
                    {filteredOptions.map(option => (
                        <div
                            key={option.currencyShortName}
                            className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                                onChange(option.currencyShortName);
                                setIsOpen(false);
                            }}>
                            <img
                                src={`${imageBaseUrl}${!option.currencyType ? cryptoBalancesData.find(item => item.currencyShortName === option.currencyShortName).currencyShortName : option?.currencyIcon}`}
                                alt={option.currencyShortName}
                                className="w-8 h-8 mr-2"
                            />
                            <div>
                                <p className="font-normal text-base text-brand-black">
                                    {option?.currencyShortName === 'USDT'
                                        ? `Tether (USDT)`
                                        : `${option?.currencyShortName} (${option?.currencyFullName || option?.blockchain})`}
                                </p>
                                <p className="font-normal text-sm text-[#70707B]">
                                    {getCurrencySymbol(option.currencyShortName)}{' '}
                                    {option.currencyType === 'fiat'
                                        ? toFixedTrunc(option?.balanceAmount, 2)
                                        : option?.balanceAmount}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
