// Account and accounts-details page
const Download = '/adminUi/download.svg';
const Edit = '/adminUi/edit.svg';
const Filter = '/adminUi/filter.svg';
const MobileFilter = '/adminUi/filter-mobile.svg';
const Freeze = '/adminUi/freeze.svg';
//! kris:delete const Copy = '/adminUi/copy.svg';
const Delete = '/adminUi/delete.svg';
const Setting = '/adminUi/settingIcon.svg';
const Deposit = '/adminUi/deposit.svg';
const SettingIcon = '/adminUi/settings.svg';
const Tpp = '/adminUi/tpp.svg';
const Transfer = '/adminUi/transfer.svg';
const Withdrawal = '/adminUi/withdraw.svg';
const FX = '/adminUi/fx.svg';
const SellCrypto = '/adminUi/sell-crypto.svg';
const BuyCrypto = '/adminUi/buy-crypto.svg';
const SuccessTick = '/adminUi/successTick.svg';
const Error = '/adminUi/error.svg';
const DeleteIcon = '/adminUi/delete-icon.svg';

const ArrowUpIcon = '/adminUi/arrowUpIcon.svg';
const Fiat = '/adminUi/fiat.svg';
const Crypto = '/adminUi/crypto.svg';
const DocumentIcon = '/adminUi/documentIcon.svg';
const AdminLogo = '/adminUi/admin-logo.svg';
const AdminLogoText = '/adminUi/admin-logo-text.svg';
const ChevronBottomIcon = '/adminUi/chevron-bottom.svg';
const EditPencilIcon = '/adminUi/edit-pencil.svg';
const DefaultUserIcon = '/adminUi/default-user-icon.svg';
const Info = '/adminUi/info.svg';
const MaintenanceIcon = '/adminUi/maintenance.svg';

const WalletIcon = '/adminUi/walletIcon.svg';
const PencilDots = '/adminUi/pencilDots.svg';
const BTC = '/common/crytoicons/btc.png';
const USDT = '/cryptonpay/usdt.png';
const LTC = '/common/crytoicons/ltc.png';
const ETH = '/common/crytoicons/eth1.png';
const WarningIcon = '/adminUi/warningIcon.svg';
const MerchantIcon = '/adminUi/merchantIcon.svg';
const BalanceHistory = '/adminUi/balanceHistory.svg';
const PDF = '/adminUi/pdf.svg';
const Excel = '/adminUi/excel.svg';
const NotFoundFrame = '/adminUi/notFoundFrame.svg';
const DefrostIcon = '/adminUi/defrostIcon.svg';
const FreezeConfirmation = '/adminUi/freezeConfirmation.svg';
const DefrostConfirmation = '/adminUi/defrostConfirmation.svg';
const Flag = '/adminUi/flag.svg';
const Qrcode = '/common/qrcode.svg';

export {
    Flag,
    Info,
    Download,
    Edit,
    Filter,
    MobileFilter,
    Freeze,
    Delete,
    Setting,
    Deposit,
    SettingIcon,
    Tpp,
    Transfer,
    Withdrawal,
    FX,
    ArrowUpIcon,
    DocumentIcon,
    SuccessTick,
    Error,
    DeleteIcon,
    WalletIcon,
    Fiat,
    Crypto,
    SellCrypto,
    BuyCrypto,
    AdminLogo,
    AdminLogoText,
    BTC,
    USDT,
    LTC,
    ETH,
    ChevronBottomIcon,
    EditPencilIcon,
    DefaultUserIcon,
    WarningIcon,
    MerchantIcon,
    PencilDots,
    BalanceHistory,
    PDF,
    Excel,
    DefrostIcon,
    FreezeConfirmation,
    DefrostConfirmation,
    NotFoundFrame,
    MaintenanceIcon,
    Qrcode,
};
