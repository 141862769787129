import React, { useState } from 'react';
import { EditOutlined, BankOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, List, Input, Form, Spin } from 'antd';

const SavedBeneficiaryAccounts = ({
    savedBeneficiaryAccounts,
    openEditAccountModal,
    withdrawToSaveAccount,
}) => {
    const [savedFilterAccounts, setSavedFilterAccounts] = useState(savedBeneficiaryAccounts || []);
    const [savedAccountSearchValue, setSavedAccountSearchValue] = useState('');

    const onInputChange = event => {
        const searchVal = event.target.value;
        setSavedAccountSearchValue(searchVal);

        const filteredAccounts = (savedBeneficiaryAccounts || []).filter(item =>
            item.accountNickName.toLowerCase().includes(searchVal.toLowerCase()),
        );
        setSavedFilterAccounts(filteredAccounts);
    };

    return (
        <>
            {/* Search Bar */}

            <Input
                className="w-full  p-2"
                value={savedAccountSearchValue}
                placeholder="Search Account"
                data-e2e="search-box"
                suffix={
                    <>
                        {savedAccountSearchValue && <Spin size="small" />}
                        <SearchOutlined />
                    </>
                }
                name="searchAccount"
                onChange={onInputChange}
            />

            {/* List of Accounts */}
            <List
                className="custom-beneficiary-list lg:mt-[30px] mt-[36px]"
                itemLayout="horizontal"
                dataSource={savedFilterAccounts}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                onClick={() => openEditAccountModal(item)}
                                key={item._id}
                                icon={<EditOutlined />}
                            />,
                        ]}>
                        <List.Item.Meta
                            avatar={
                                <BankOutlined
                                    data-e2e={item.accountNickName}
                                    className="text-2xl"
                                />
                            }
                            title={item.accountNickName}
                            description={item.beneficiaryAddress}
                            onClick={() => withdrawToSaveAccount(item)}
                        />
                    </List.Item>
                )}
            />
        </>
    );
};

export default SavedBeneficiaryAccounts;
