import { useSelector } from 'react-redux';
import { CryptoSelectModal } from './CryptoSelectModal';
import React, { useEffect, useState } from 'react';
import apiRequest from '../../utils/api';
import { Button, message, Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CopyButton } from '../../utils/common';
import CryptoAddressConfirmationModal from './CryptoAddressConfirmationModal';

export const CryptoAddresseModal = ({
    onBack,
    open,
    getCryptoAddress,
    addressData,
    addressActions,
}) => {
    const balancesCrypto = useSelector(state => state.account.cryptoBalancesData);
    const { clientData } = useSelector(state => state.account);
    const email = localStorage.getItem('email');
    const [formData, setFormData] = useState({
        cryptoId: 'BTC',
        nickName: '',
        address: '',
    });
    const [isConfirm, setIsConfirm] = useState(false);

    useEffect(() => {
        if (addressData && addressActions?.edit) {
            setFormData({
                cryptoId: addressData?.cryptoId,
                nickName: addressData?.nickName,
                address: addressData?.address,
            });
        } else {
            setFormData({
                cryptoId: 'BTC',
                nickName: '',
                address: '',
            });
        }
    }, [addressData, addressActions]);

    const saveNewAddress = async () => {
        const body = {
            email: email,
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
        };
        const response = await apiRequest('/save-crypto-address', 'POST', body);
        if (response.success) {
            onBack();
            message.success('Address added successfully');
            setFormData({
                cryptoId: 'BTC',
                nickName: null,
                address: '',
            });
            getCryptoAddress();
        } else {
            message.error(response.error);
            return { error: response.error };
        }
    };
    const updateAddress = async () => {
        const body = {
            email: email,
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            addressId: addressData._id,
        };
        const response = await apiRequest('/update-crypto-address', 'POST', body);
        if (response.success) {
            onBack();
            message.success('Address updated successfully');
            setFormData({
                cryptoId: 'BTC',
                nickName: null,
                address: '',
            });
            getCryptoAddress();
        } else {
            message.error(response.error);
            return { error: response.error };
        }
    };

    return (
        <>
            <Modal
                className="sell-crypto-modal common-mobile-view "
                width={570}
                centered
                title={
                    <div className="flex items-center">
                        <ArrowLeftOutlined
                            className="text-xl mr-[12px] w-6 h-6"
                            onClick={() => {
                                onBack();
                            }}
                        />
                        <p className="text-2xl font-semibold relative flex">
                            {addressActions?.add ? 'Add Address' : 'Edit Address'}
                        </p>
                    </div>
                }
                open={(open && addressActions?.add) || addressActions?.edit}
                onCancel={() => {
                    onBack();
                }}
                handleClose={() => {
                    onBack();
                }}
                footer={
                    <div className="flex justify-end w-full">
                        <Button
                            key="address"
                            type="primary"
                            onClick={() => {
                                if (addressActions?.edit) {
                                    setIsConfirm(true);
                                } else {
                                    saveNewAddress();
                                }
                            }}
                            className="rounded-full px-8 py-3 h-[46px] w-full sm:w-auto mr-3">
                            {addressActions?.edit ? `Save address` : `Add Address`}
                        </Button>
                    </div>
                }>
                <>
                    <div className="mt-8 grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Select crypto:
                        </h1>
                        <div className="col-span-2">
                            <CryptoSelectModal
                                value={formData.cryptoId}
                                onChange={e => setFormData({ ...formData, cryptoId: e })}
                                options={balancesCrypto}
                                className="!rounded-l-3xl"
                            />
                        </div>
                    </div>
                    <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Nickname:
                        </h1>
                        <input
                            type="text"
                            placeholder="Create address nickname"
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    nickName: e.target.value,
                                })
                            }
                            value={formData.nickName}
                            className="bg-input col-span-2 rounded-3xl w-full py-[17px] pl-6"
                        />
                    </div>
                    <div className="mb-11 grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Wallet Address:
                        </h1>
                        <div className="col-span-2 bg-input border-[#D1D1D6] rounded-3xl py-[17px] pl-6 overflow-hidden">
                            <input
                                type="text"
                                placeholder="Enter wallet address"
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        address: e.target.value,
                                    })
                                }
                                value={formData.address}
                                className="bg-input w-10/12"
                            />
                            <CopyButton msg={formData.address} />
                        </div>
                    </div>
                </>
            </Modal>
            <CryptoAddressConfirmationModal
                open={isConfirm && addressActions.edit}
                onCancel={() => setIsConfirm(false)}
                data={formData.address}
                handleConfirm={() => {
                    updateAddress();
                    setIsConfirm(false);
                }}
                handleClose={() => setIsConfirm(false)}
            />
        </>
    );
};
